<template>
  <div class="login">
    <!-- <img src="../assets/images/logo.png" alt="logo" class="logo" /> -->
    <div class="logo"></div>
    <h1>登录</h1>
    <Form>
      <FormItem prop="username">
        <Input type="text" v-model="username" placeholder="用户">
          <Icon type="ios-person-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="password">
        <Input type="password" v-model="password" placeholder="密码">
          <Icon type="ios-lock-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem>
        <div class="button-container">
          <Button
            type="primary"
            size="large"
            @click="login"
            :loading="isLoading"
          >
            登录
          </Button>
        </div>
      </FormItem>
    </Form>
    <div class="link-container">
      <!-- <RouterLink to="/registration" class="action-link">注册账号</RouterLink> -->
      <RouterLink to="/forget-password" class="action-link">
        忘记密码？
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { Form, FormItem, Input, Icon, Button, Message } from "view-design";
import { login } from "../service/auth";

export default {
  name: "Login",
  components: {
    Form,
    FormItem,
    Input,
    Icon,
    Button,
  },
  data() {
    return {
      username: "",
      password: "",
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        const data = {
          password: this.password,
          username: this.username,
        };
        const result = await login(data);
        const { token, id, username } = result.data;
        this.$store.commit("setToken", token);
        this.$store.commit("setUserInfo", {
          id,
          username,
        });
        this.$router.push("/");
      } catch (error) {
        Message.error(error.response?.data?.message ?? "服务器错误");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.login {
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto;
}

.logo {
  width: 80%;
  display: block;
  margin: 100px auto 50px;
}

.button-container {
  text-align: center;
}

.login h1 {
  text-align: center;
  margin-bottom: 20px;
}
.link-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-link {
  text-align: center;
  margin: 0 10px;
  display: block;
}
</style>
