import request from "./base";

export function login(data) {
  return request.post("/login", data);
}

export function registration(data) {
  return request.post("/user", data);
}

export function changePassword(data) {
  return request.post("/user/change-password", data);
}

export function forgetPassword(data) {
  return request.post("/user/forget-password", data);
}

export function resetPassword(data) {
  return request.post("/user/reset-password", data);
}
